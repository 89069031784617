export default [
  { header: 'Radio' },
  {
    title: 'Radios',
    route: 'RadioList',
    icon: 'RadioIcon',
  },
  // {
  //   title: 'Presenters',
  //   route: 'Presenters',
  //   icon: 'ShieldIcon',
  // },
  // {
  //   title: 'Programs',
  //   route: 'Programs',
  //   icon: 'ShieldIcon',
  // },
  // {
  //   title: 'Podcasts',
  //   route: 'Podcasts',
  //   icon: 'ShieldIcon',
  // },
  // {
  //   title: 'Schedule',
  //   route: 'Schedule',
  //   icon: 'ShieldIcon',
  // },
  { header: 'Configuration' },
  {
    title: 'Admin Users',
    route: 'admin-users-page',
    icon: 'FileIcon',
  },
  {
    title: 'Settings',
    route: 'settings-page',
    icon: 'ShieldIcon',
  },
]
